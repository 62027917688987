.BackgroundImage {
    height: 540px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}

.icons {
    display: flex;
    align-items: center;
    justify-content: center;
    background: blue;
    border-radius: 50%;
    border-color: white;
    border-style: solid;
    border-width: 2px;
    width: 30px;
    height: 30px;
}

.icons.active {
    background: red;
}

#container {
    height: auto;
    position: relative;
}

#background,
#infoi {
    width: 100%;
    top: 0;
    left: 0;
    height: auto;
}

#infoi {
    z-index: 10;
}