.App {
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 1440px;
  height: 100%;
}

.App-container {
  height: 100% - 60px;
  padding-top: 32px;
  padding-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
}

#footer {
  bottom: 0;
  margin-top: auto;
}