#road-sign {

    background-color: #FFFFFF;
    border: 25px solid #EE0000;
    border-radius: 200px;
    margin: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;

}

#sign-center {
    width: 200px;
    height: 200px;
    position: relative;
}

#limit {
    color: #000000;
    font-family: Trebuchet MS;
    font-size: 110px;
    font-weight: 800;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#limit sub {
    vertical-align: text-bottom;
    font-size: 70px;
}

#limit-sub {
    color: #000000;
    font-family: Trebuchet MS;
    font-size: 80px;
    font-weight: 800;
    position: absolute;
    top: 55%;
    left: 85%;
    transform: translate(-50%, -50%);
}