.RestrictionsSigns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.RestrictionsText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.RestrictionsText p {
    font-family: Trebuchet MS;
    font-size: 48px;
    font-weight: 800;
    letter-spacing: -1px;
    color: #000000;
    margin: 0px;
    text-align: center;
}