.StatusDashboard {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.side {
    text-align: start;
    flex: 1;

}

.left-side {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.right-side {
    width: 50%;
    margin-left: auto;
}