.Logo {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.IoTConstructionLogo {
    height: 120px;
    margin-right: 20px;
    -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.19)#f5f5f5);
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.19));
}

.LogoClient {
    height: 80px;
    margin-right: 20px;
    margin-left: auto;
}