.LocationDropDown {
    width: 250px;
}

.dataManager {
    display: flex;
    flex-direction: row;
}

.dataManagerSelect {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    align-items: center;
}

.dataManagerSelect h4 {
    margin-right: 10px;
}