.ClientSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.ClientSectionLogo {
    width: 300px;
    height: auto;
    border: none;
    background: none;
    margin-bottom: 8px;
}

.ClientSectionLogoImage {
    height: 180px;
    height: 180px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.ClientSectionLogoImage img {
    max-width: 80%;
    max-height: 80%;
    display: block;
}

.logo {
    width: 300px;
    height: auto;
    border: none;
    background: none;
    margin-bottom: 8px;

}

.ClientCards {
    display: flex;
    flex-direction: row;
}

.Card {
    margin: 8px;
    width: 350px;
    height: 250px;
}