.ObjectStatus {
    border-radius: 80px;
    border: 4px solid green;
    margin-left: auto;
    justify-content: space-between;
    width: fit-content;
    padding: 6px;
    margin-right: 16px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;

}

.ObjectStatusValue {
    display: flex;
    flex-direction: row;
    height: 60px;
}

.leftButton {
    display: flex;
    background-color: #E9E9E9;
    border: 2px solid #E9E9E9;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
}

.leftButton.active {
    background-color: green;
    border: 2px solid green;
}

.leftButton.active p {
    color: #FFF;
}

.centerButton {
    display: flex;
    background-color: #E9E9E9;
    border: 2px solid #E9E9E9;
    margin-left: 4px;
    margin-right: 4px;
}

.centerButton.active {
    background-color: orange;
    border: 2px solid orange;
}

.centerButton.active p {
    color: #FFF;
}

.rightButton {
    display: flex;
    background-color: #E9E9E9;
    border: 2px solid #E9E9E9;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
}

.rightButton.active {
    background-color: red;
    border: 2px solid red;
}

.rightButton.active p {
    color: #FFF;
}

.ObjectStatusTitle {
    position: sticky;
    top: -20px;

}

.ObjectStatusTitle p {
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    color: green;
    font-size: 12px;
    font-weight: 600;
    background-color: #FFF;
}