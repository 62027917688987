.Footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: auto;
    width: 100%;
}

.StatusSafe {
    width: 100%;
    background-color: green;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.StatusWarning {
    width: 100%;
    background-color: orange;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.StatusAllert {
    width: 100%;
    background-color: red;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Status p {
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-size: 16px;
    font-weight: 300;
}